import { Component, OnInit } from '@angular/core';
import { Location, } from '@angular/common';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { ValidateService } from 'src/app/services/validate/validate.service';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { pays } from '../../pays/pays';
import { tri } from '../../pays/pays';
import { villes } from '../../pays/villes';
import { codepostale } from '../../pays/villes';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-ajout-medecin',
  templateUrl: './ajout-medecin.component.html',
  styleUrls: ['../medecins/medecins.component.css','./ajout-medecin.component.scss']
})
export class AjoutMedecinComponent implements OnInit {

  form: any;
  admin: any;
  pays: any[] = [];
  errorNumeroInscription: any;
  successRegister: any;
  medecins: any[] = [];
  selectedpays = "Tunisie"
  selectedville = ''
  listePostal = [ ];
  listefiltrecode = ['2001','2002','2020','2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058',  '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094','2095']
  listeVilles:any[]=[];
  isTunisie = true
  listefiltreville = [ ];
  selectedpostal = ''
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {}
  showerrorligne = ""
  today = new Date().toISOString().split('T')[0]
  basedDoctor:any;
  constructor(private validateService:ValidateService,private medecinService: MedecinService, private adminService: AdminService, private authService: AuthService, location: Location, private activatedRoute: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    this.pays = pays.sort(tri);
    this.errorNumeroInscription = false;
    this.successRegister = false;
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, ValidationService.emailValidator, Validators.maxLength(50)]],
      tel: ['+216', [Validators.required]],
      telFixe: ['+216',],
      telPerso: ['+216'],
      numInscription: ['', [Validators.required, ValidationService.numberValidator, Validators.maxLength(25)]],
      specialite: ['', Validators.required],
      dateAnniversaire: ['', [ValidationService.dateValidator]],
      sexe: ['Masculin'],
      adresseCabinet: this.formBuilder.group({
        adresse: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(500)]],
        ville: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
        pays: ['Tunisie', Validators.required],
        codePostal: ['', [Validators.required, ValidationService.numberValidator, Validators.maxLength(4)]],
      }),
      diplome: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      codeCNAM: ['', [ValidationService.numberValidator, Validators.maxLength(25)]],
      centreCNAM: ['', Validators.maxLength(25)],
      dateDebutFonction: [''],
      languesParlees: ['', []],
      dateInscription: [''],
      notes: ['', Validators.maxLength(1000)],
    })
    this.form.get('telFixe').clearValidators();
    this.form.get('telFixe').updateValueAndValidity();
    this.form.get('telPerso').clearValidators();
    this.form.get('telPerso').updateValueAndValidity();
    this.dropdownList = [{ "id": 1, "itemName": "Allemand" },
    { "id": 2, "itemName": "Arabe" },
    { "id": 3, "itemName": "Anglais" },
    { "id": 4, "itemName": "Français" },
    { "id": 5, "itemName": "Italien" },
    ];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Choisissez langues ",
      selectAllText: 'Sélectionnez tous',
      unSelectAllText: 'Désélectionne tous',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    let basedDoctorId =  localStorage.getItem("idDoctorExisted")
    if(basedDoctorId && basedDoctorId != null && basedDoctorId != undefined && basedDoctorId.length < 30){
      this.medecinService.getMedecinIntegreById(basedDoctorId).subscribe(medecin => {
        this.basedDoctor = medecin;
        localStorage.removeItem('idDoctorExisted')
        if (this.basedDoctor) {
          this.isTunisie=true
          //this.onSelectpays('Tunisie')
          if(this.basedDoctor.tel && this.basedDoctor.tel.indexOf("+") === -1){
          this.basedDoctor.tel = '+' + this.basedDoctor.tel
          }
          if( this.basedDoctor.dateInscription &&  this.basedDoctor.dateInscription != null &&  this.basedDoctor.dateInscription != undefined &&  this.basedDoctor.dateInscription != ""){
            this.basedDoctor.dateInscription = new Date(this.basedDoctor.dateInscription).toISOString().substring(0, 10);
          }
          if(this.basedDoctor.adresseCabinet && this.basedDoctor.adresseCabinet.ville){
            let myville = this.basedDoctor.adresseCabinet.ville.charAt(0).toUpperCase() + this.basedDoctor.adresseCabinet.ville.slice(1).toLowerCase();
            this.onSelectville(myville)
            this.basedDoctor.adresseCabinet.ville = myville
          }
        }
      },
        err => {
          return false;
        });
    }
  }
  onItemSelect(item: any) {

  }
  OnItemDeSelect(item: any) {

  }
  onSelectAll(items: any) {

  }
  onDeSelectAll(items: any) {

  }
  onSelectville(selectedville){
    this.listePostal = codepostale.filter(x => x.ville == selectedville);
    this.listePostal.forEach(index => this.listefiltrecode = index.code);
    this.selectedville = selectedville
  }

  onSelectpays(selectedpays){
    if(selectedpays === 'Tunisie' || selectedpays === ''){
      this.isTunisie = true;
      this.listeVilles = villes.filter(x => x.pays == selectedpays);
      this.listeVilles.forEach(index =>
        this.listefiltreville = index.ville);
    } else {
      this.selectedville = undefined
      this.selectedpostal = undefined
      this.form.controls['adresseCabinet'].controls['ville'].setValue('');
      this.form.controls['adresseCabinet'].controls['codePostal'].setValue('');
      this.isTunisie = false;
    }
  }
  // enregistrer un médecin
  onRegisterMedecin() {
    if(!this.form.value.nom || this.form.value.nom.length < 3 || this.form.value.nom.length > 25 || !this.form.value.prenom || this.form.value.prenom.length < 3 || this.form.value.prenom.length > 25){
      this.showerrorligne = "Merci de vérifier les champs 'Nom & Prénom' (minimum 3, maximum 25)"
      return false;
    }
    if(!this.form.value.email || this.form.value.email.length < 3 || this.form.value.email.length > 50 ){
      this.showerrorligne = "Merci de vérifier le champ 'Email' (minimum 3, maximum 50)"
      return false;
    }
    if(this.form.value.email && this.form.value.email.length > 1 && !this.validateService.validateEmail(this.form.value.email)){
      this.showerrorligne = "Merci de vérifier le champ 'Email'"
      return false;
    }
    if(!this.form.value.numInscription || this.form.value.numInscription.length < 3 || this.form.value.numInscription.length > 25 || this.form.value.numInscription.match(/^[0-9]+$/) === null){
      this.showerrorligne = "Merci de vérifier le champ 'N° d'inscription au conseil de l'ordre' (minimum 3, maximum 25)"
      return false;
    }
    if(!this.form.value.tel || (this.form.value.tel && this.form.value.tel.substr(0, 4) == "+216" && this.form.value.tel[4] != "2" && this.form.value.tel[4] != "9" && this.form.value.tel[4] != "5" && this.form.value.tel.length != 12)){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 1'"
      return false;
    }
    if(this.form.value.tel.length != 12){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 1'"
      return false;
    }
    if((this.form.value.telFixe && this.form.value.telFixe.length > 4 && this.form.value.telFixe.length != 12)){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 2'"
      return false;
    }
    if((this.form.value.telPerso && this.form.value.telPerso.length > 4 && this.form.value.telPerso.length != 12)){
      this.showerrorligne = "Merci de vérifier le champ 'Contact 3'"
      return false;
    }
    if(!this.form.value.diplome || this.form.value.diplome.length < 3 || this.form.value.diplome.length > 100){
      this.showerrorligne = "Merci de vérifier le champ 'Diplôme' (minimum 3, maximum 100)"
      return false;
    }
    if(!this.form.value.specialite){
      this.showerrorligne = "Merci de vérifier le champ 'Spécialité'"
      return false;
    }
    if(this.form.value.dateAnniversaire && this.form.value.dateAnniversaire > this.today){
      this.showerrorligne = "Merci de vérifier le champ 'Date de naissance'"
      return false;
    }
    if(this.form.value.codeCNAM && this.form.value.codeCNAM.length > 25 || this.form.value.codeCNAM && this.form.value.codeCNAM.match(/^[0-9]+$/) === null){
      this.showerrorligne = "Merci de vérifier le champ 'Code CNAM' (maximum 25)"
      return false;
    }
    if(this.form.value.centreCNAM && this.form.value.centreCNAM.length > 25){
      this.showerrorligne = "Merci de vérifier le champ 'Centre CNAM' (maximum 25)"
      return false;
    }
    if(!this.form.value.adresseCabinet.adresse ||  this.form.value.adresseCabinet.adresse.length < 3 ||  this.form.value.adresseCabinet.adresse.length > 500){
      this.showerrorligne = "Merci de vérifier le champ 'Adresse' (minimum 3, maximum 500)"
      return false;
    }
    if(!this.form.value.adresseCabinet.ville ||  this.form.value.adresseCabinet.ville.length < 3 ||  this.form.value.adresseCabinet.ville.length > 25){
      this.showerrorligne = "Merci de vérifier le champ 'Ville' (minimum 3, maximum 25)"
      return false;
    }
    if(!this.form.value.adresseCabinet.codePostal ||  this.form.value.adresseCabinet.codePostal.length != 4 || (this.form.value.adresseCabinet.codePostal && this.form.value.adresseCabinet.codePostal.match(/^[0-9]+$/) === null)){
      this.showerrorligne = "Merci de vérifier le champ 'Code postal' (maximum 4)"
      return false;
    }
    this.showerrorligne = ""
    this.form.value.accepted = true;
    this.medecinService.verifyNumInscription(this.form.value).subscribe((data:any) => {
      if (data.success) {
        this.errorNumeroInscription = true;
        this.showerrorligne = "Le numéro d'inscription a été déjà utilisé"
        this.successRegister = false;
        return false;
      }
      else {
        this.medecinService.registerMedecin(this.form.value).subscribe(medecin => {
          this.medecins.push(medecin);
          this.form.reset();
          this.errorNumeroInscription = false;
          this.successRegister = true;
          Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le médecin a été AJOUTÉ avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
    this.router.navigate(['/medecins'], {
      queryParams: {refresh: new Date().getTime()}
    });
        });
      }
    })
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
}

