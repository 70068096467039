<header class="bg-surface  pt-6">
    <div style="float: right;padding-right: 2%;" class="col-lg-5 col-md-6 col-sm-12">
        <ul class="breadcrumb float-md-right">
            <li class="breadcrumb-item color-blue">
                <a class="color-blue" routerLink="/admin">Evénements</a>
            </li>
            <li class="breadcrumb-item active color-blue " style="cursor: default;">Modifier un événements</li>
        </ul>
    </div>
    <br>
    <br>
    <div class="container-fluid">
        <div class="row align-items-center mb-4">
            <!-- Title -->
            <div class="col-md-8 col-12 mb-2 mb-md-0 d-flex align-items-center">
                <h1 class="h2 mb-0 ls-tight color-blue title-1">Modifier un événement</h1>
            </div>
        </div>
        <!-- Nav -->
        <ul class="nav nav-tabs mt-4 overflow-x border-0"></ul>
    </div>
</header>
<br>
<div class="container-fluid pb-6">
    <div class="card m-3-cust p-4">
        <form [formGroup]="registerForm" >
            <div class="form-row">
                <div class="form-group col">
                    <label for="title">Titre <b style="color: red;"> *</b></label>
                    <input id="title" type="text" class="form-control input-custom" formControlName="title" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="dateDebut">Date début <b style="color: red;"> *</b></label>
                    <input id="dateDebut" type="date" class="form-control input-custom" formControlName="dateDebut" />
                </div>
                <div class="form-group col">
                    <label for="dateFin">Date de fin <b style="color: red;"> *</b></label>
                    <input id="dateFin" type="date" class="form-control input-custom" formControlName="dateFin" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="url">URL <b style="color: red;"> *</b></label>
                    <input id="url" type="text" class="form-control input-custom" formControlName="url" readonly />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="description">Description <b style="color: red;"> *</b></label>
                    <angular-editor id="description" [config]="editorConfig" formControlName="description"></angular-editor>
                </div>
            </div>
            <div class="form-row">
                <label>Images</label>
                <ngx-file-drop 
                    dropZoneLabel="Déposez les fichiers ici" 
                    (onFileDrop)="dropped($event)" 
                    (onFileOver)="fileOver($event)" 
                    (onFileLeave)="fileLeave($event)" style="width: 100%;">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <p style="color: #636363;">Déposez vos images ici</p>
                        <a (click)="openFileSelector()">
                            <i class="bi bi-file-earmark-arrow-down i-file"></i>
                        </a>
                    </ng-template>
                    <p style="color: #636363;">Les types d'images doivent être webp</p>
                </ngx-file-drop>
                <div class="upload-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Fichier :</th>
                            </tr>
                        </thead>
                        <tbody class="upload-name-style">
                            <tr *ngFor="let item of listFiles; let i=index">
                                <td><strong>{{ item }}</strong></td>
                                <td>
                                    <i (click)="deleteFileFromListFiles(item)" 
                                       style="color: #23b1a5; cursor: pointer;" 
                                       title="Supprimer" 
                                       class="bi bi-trash"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="metaTitle">Meta title <b style="color: red;"> *</b></label>
                    <input id="metaTitle" type="text" class="form-control input-custom" formControlName="metaTitle" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="metaDescription">Meta description <b style="color: red;"> *</b></label>
                    <input id="metaDescription" type="text" class="form-control input-custom" formControlName="metaDescription" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="keyWords">Key words (séparés par une virgule) <b style="color: red;"> *</b></label>
                    <input id="keyWords" type="text" class="form-control input-custom" formControlName="metaKeywords" />
                </div>
            </div>
            <div class="text-center" style="padding-top: 3%;">
                <button routerLink="/admin" class="btn btn-secondary mr-4 button_list mb-2" type="button">Annuler</button>
                <button class="btn btn-primary button_list mb-2" type="submit" (click)="updateEvent()">Sauvegarder</button>
            </div>
        </form>
    </div>
</div>
