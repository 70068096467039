import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupArchiverEventComponent } from '../popup/popup-archiver-event/popup-archiver-event.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupMasquerEventComponent } from '../popup/popup-masquer-event/popup-masquer-event.component';
import { _getURL } from 'config/API_CONFIG';
import { EvenementV2Service } from 'src/app/services/evenement/evenement-v2.service';

@Component({
  selector: 'app-event-v2',
  templateUrl: './event-v2.component.html',
  styleUrls: ['./event-v2.component.css'],
  providers:[EvenementV2Service]
})
export class EventV2Component implements OnInit {
  events: any[] = [];
  baseImageUrl= _getURL('')+'/data/files/evenement/'
  constructor(private router: Router,
    private modalService: NgbModal,
    private evenementsService: EvenementV2Service
  ) { }

  ngOnInit(): void {
    this.baseImageUrl = this.baseImageUrl.replace("/api","");
    this.getEvents();
  }
  getEvents() {
    this.evenementsService.getAllEvents().subscribe(
      (response) => {
        this.events = response; // Store the fetched events
      },
      (error) => {
      }
    );
  }
  create() {
    this.router.navigate(["event/ajout"]);
  }
  update(id) {
    this.router.navigate(["event/modification", id])
  }

  archive() {
    this.router.navigate(["event/archives"]);
  }
  archiveEvent() {
    this.modalService.open(PopupArchiverEventComponent);
  }
  HideEvent() {
    this.modalService.open(PopupMasquerEventComponent);
  }
}




