<br><br><br><br>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Oups !
                </h1>
                <h2>
                    404 Page non trouvée
                </h2>
                <div class="error-details">
                    Désolé, une erreur s'est produite. La page demandée est introuvable !
                </div>
            </div>
            
        </div>
    </div>
</div>
