import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders,HttpParams} from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class MedecinService {

  constructor(private http: HttpClient) {
  }
  getMedecinNotAccepted() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/attente/medecins', { headers: headers })
      
  }
  // get all patients
  getAllPatients() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/all/all/all/all/all/all', { headers: headers })
      
  }

  getAllPatientsInscrits() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/inscrits', { headers: headers })
      
  }
  getAllPatientsInscritsByPage() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/inscrits/page/size', { headers: headers })
      
  }
  getAllPatientsInscritscount(){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/patients/list/count', { headers: headers })
      
  }
  getPateintsBypageAndRechercheWithLimite(searched){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/inscrits/searched/multiple/'+searched, { headers: headers })
      
  }
  getPateintsBypageAndRechercheWithLimiteOfMedecin(id,searched){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/'+ id +'/searched/multiple/'+searched, { headers: headers })
      
  }
  getPatientByIdMedecin(idMedecin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/' + idMedecin, { headers: headers })
      
  }
  getPatientByIdMedecinByPage(idMedecin,page,size) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/' + idMedecin + '/page/' + page + '/size/'+size, { headers: headers })
      
  }
  getPatientscount(id){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients)+'/medecin/'+ id + '/list/count/', { headers: headers })
      
  }
  getMedecinAccepted() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/accepted/medecins', { headers: headers })
      
  }
  getDataFromApiMedecin(){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped)+"/from/api", { headers: headers })
  }
  editAcceptedMedecin(medecin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/acceptMedecin/' + medecin._id + '/' + medecin.email, medecin, { headers: headers })
      
  }

  //get a medecin by its id
  getMedecinById(id) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/' + id, { headers: headers })
      
  }


  //update les rôles d'un medecins
  updateMedecin(newMedecin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/' + newMedecin._id, newMedecin, { headers: headers })
      
  }
  verifyNumInscription(admin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin) + '/verifyNumInscription', JSON.stringify(admin), { headers: headers })
      
  }
  registerMedecin(medecin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });

    return this.http.post(_getURL(API_CONFIG.medecin) + '/admin/newMedecin/add', medecin, { headers: headers })
      
  }
  getMedecinScaped() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped), { headers: headers })
      
  }
  getMedecinScapedNotAccepted() {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/notAccepted', { headers: headers })
      
  }
  getMedecinIntegreById(id) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/' + id, { headers: headers })
      
  }
  updateMedecinAdmin(newMedecin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/update/admin/all/' + newMedecin._id, newMedecin, { headers: headers })
      
  }
  updateMedecinIntegre(newMedecin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecinScraped) + '/' + newMedecin._id, newMedecin, { headers: headers })
      
  }
  registerMedecinIntegre(medecin) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecinScraped) + '/admin/newMedecin/add', medecin, { headers: headers })
      
  }
  saveAllMedecinXLS(data){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecinScraped)+'/save/all/medecins', JSON.stringify(data), {headers: headers})
        
  }
  getMedecinScapedBypage(page,size){
  
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/page/' + page + '/size/'+size, { headers: headers })
      
  }
  getMedecinScapedcount(){
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/list/count/', { headers: headers })
      
  }
  getMedecinScapedBypageAndRechercheWithLimite(sanitizedName,sanitizedFirstName,sanitizedInscription,sanitizedSpecialite,sanitizedEmail){
    let params = new HttpParams();
    if (sanitizedName) params = params.set('sanitizedName', sanitizedName);
    if (sanitizedFirstName) params = params.set('sanitizedFirstName', sanitizedFirstName);
    if (sanitizedInscription) params = params.set('sanitizedInscription', sanitizedInscription);
    if (sanitizedSpecialite) params = params.set('sanitizedSpecialite', sanitizedSpecialite);
    if (sanitizedEmail) params = params.set('sanitizedEmail', sanitizedEmail);
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/searched/multiple', {params,headers: headers })
      
  }
}
