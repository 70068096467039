<div class="content-wrapper flex-grow-1 bg-light min-vh-100" id="content" style="background-color: white !important;">
    <header class="bg-surface pt-6">
        <div style="float: right; padding-right: 2%;" class="col-lg-5 col-md-6 col-sm-12">
            
        </div>
        <br><br>
        <div class="container-fluid">
            <div class="row align-items-center mb-4">
                <!-- Title -->
                <div class="col-md-8 col-12 mb-2 mb-md-0 d-flex align-items-center">
                    <h1 class="h2 mb-0 ls-tight color-blue title-1">Liste des événements</h1>
                </div>
                <!-- Actions -->
                <div class="col-md-4 col-12 text-md-end text-center mt-2 mt-md-0">
                    <a class="btn d-inline-flex btn-sm btn-primary" (click)="create()">
                        <span class="button_list" style="color: #fff">Ajouter un événement</span>
                    </a>
                </div>
            </div>
            <!-- Nav -->
            <ul class="nav nav-tabs mt-4 overflow-x border-0"></ul>
        </div>
    </header>
    <main class="container-fluid pb-6">
        <div class="card shadow border-2 mt-3 mb-3">
            <div class="card-header col-12" style="background: white;">
                <div class="row">
                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
                        <h4 class="titlepage">Evénements</h4>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
                        <!-- <h4 class="titleotherpage"><a (click)="archive()">Evénements archivés</a></h4> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow border-2">
            <!-- Row for cards -->
            <!-- Add this inside your HTML where you want to display the events -->
            <div class="row p-3">
                <div *ngFor="let event of events" class="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4">
                    <div class="card shadow border-3">
                        <img [src]="baseImageUrl + event.key + '/'+ event.image"
                            class="card-img-top img-fluid card-image" alt="Event Image" />
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color: #21726B;">
                                {{ event.title }}
                            </h5>
                            <h5 class="card-text text-center" style="color: #21726B;">
                                {{ event.dateDebut | date: 'dd/MM/yyyy' }}
                            </h5>
                            <div class="d-flex justify-content-center mt-3">
                                <a (click)="update(event._id)" class="mr-2"><i class="bi bi-pencil"></i></a>
                                <a (click)="HideEvent()" class="mr-2"><i class="bi bi-eye-slash"></i></a>
                                <a (click)="archiveEvent()"><i class="bi bi-archive"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>