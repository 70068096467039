import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-archived-event',
  templateUrl: './archived-event.component.html',
  styleUrls: ['./archived-event.component.css']
})
export class ArchivedEventComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
events(){
  this.router.navigate(["admin"]);
}
}
