<header class="bg-surface  pt-6">
    <div style="float: right;padding-right: 2%;" class="col-lg-5 col-md-6 col-sm-12">
        <ul class="breadcrumb float-md-right">
            <li class="breadcrumb-item color-blue">
                <a class="color-blue" routerLink="/admin">Evénements</a>
            </li>
            <li class="breadcrumb-item active color-blue " style="cursor: default;">Ajouter un événements</li>
        </ul>
    </div>
    <br>
    <br>
    <div class="container-fluid">
        <div class="row align-items-center mb-4">
            <!-- Title -->
            <div class="col-md-8 col-12 mb-2 mb-md-0 d-flex align-items-center">
                <h1 class="h2 mb-0 ls-tight color-blue title-1">Ajouter un événement</h1>
            </div>
        </div>
        <!-- Nav -->
        <ul class="nav nav-tabs mt-4 overflow-x border-0"></ul>
    </div>
</header>
<br>
<div class="container-fluid pb-6 ">
    <div class="card m-3-cust p-4">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col">
                    <label>Titre <b style="color: red;"> * </b></label>
                    <input type="text" formControlName="title" class="form-control input-custom" 
                    [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">Champ obligatoire.</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label>Date début <b style="color: red;"> * </b></label>
                    <input type="date" formControlName="dateDebut" class="form-control input-custom" />
                </div>
                <div class="form-group col">
                    <label>Date de fin <b style="color: red;"> * </b></label>
                    <input  formControlName="dateFin" type="date" class="form-control input-custom" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label>URL</label>
                    <input type="text" id="url" formControlName="url" class="form-control input-custom" readonly/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label>Description <b style="color: red;"> * </b></label>
                    <angular-editor 
                    formControlName="description"
                    [config]="editorConfig"
                    [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
                    </angular-editor>


                </div>

            </div>
            <div class="form-group row" class="center">
                <label>Images</label>
                <ngx-file-drop  dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
                (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                    <ng-template  ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                      <p style="color: #636363;">Déposer vos images ici </p> 
                  
                      <a (click)="openFileSelector()"><i class="bi bi-file-earmark-arrow-down i-file"></i>
                       
                      </a>
                    </ng-template>
             
                    <p style="color: #636363;">les types d'images doivent étre webp </p> 
                </ngx-file-drop>
                <div class="upload-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Fichier :</th>
                            </tr>
                        </thead>
                        <!-- <h2>fff  {{listFiles | json }}</h2> -->
                        <tbody class="upload-name-style">
                            <tr *ngFor="let item of listFiles; let i=index">
                                <td><strong>{{ item}}</strong></td>
                                <td><i (click)="deleteFileFromListFiles(item)" style="color: #23b1a5;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                    <label>Meta title <b style="color: red;"> * </b></label>
                    <input type="text" formControlName="metaTitle" class="form-control input-custom" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label>Meta description <b style="color: red;"> * </b></label>
                    <input type="text" formControlName="metaDescription" class="form-control input-custom" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label>Key words (séparés par un virgule) <b style="color: red;"> * </b></label>
                    <input type="text" formControlName="metaKeywords" class="form-control input-custom" />
                </div>
            </div>
            <div style="padding-top: 3%;" class="text-center">
                <button routerLink="/admin" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
                <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
            </div>
        </form>
    </div>
</div>