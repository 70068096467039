import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONFIG, _getURL } from 'config/API_CONFIG';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const headers = new HttpHeaders({
  'Authorization': localStorage.getItem('id_token_adminMedivisto'),
  'Content-Type': 'application/json'
});
@Injectable()
export class EvenementV2Service{
  
  private _eventURL = _getURL(API_CONFIG.evenement)
  
  constructor(private http: HttpClient) { }
  uploadFiles(files, key): Observable<any> {
    return this.http.post(`${this._eventURL}/uploads/evenement/${key}`, files);
  }

  addEvent(evenement: any): Observable<Object> {
    const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('id_token_adminMedivisto'),
        'Content-Type': 'application/json'
      });
    return this.http.post(`${this._eventURL}/add`, evenement, {headers: headers});
  }
  getAllEvents(): Observable<any> {
    return this.http.get(`${this._eventURL}/allEvent`);
  }
  getEvent(id: string): Observable<any> {
    return this.http.get<any>(`${this._eventURL}/${id}`,{headers: headers});
  }
  updateEvent(id: string, evenement: any): Observable<Object> {
    return this.http.put(`${this._eventURL}/update/${id}`, evenement,{headers: headers});
  }

}
