import { Component, OnInit } from '@angular/core';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { Router } from '@angular/router';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { uploadFolderRootAdmin } from '../../../../config/API_CONFIG';
import { ImageService } from 'src/app/services/image/image.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
declare var $: any

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.css']
})
export class BackofficeComponent implements OnInit {

  image: any;
  updatedNom: any;
  updatedPrenom: any;
  notifications: any[] = [];
  notificationsPatient: any[] = [];
  interval: any;
  notification: any;
  token: any;
  admin: any;
  lengthNotification: any;
  urlImage: any;
  adminNom: any;
  is_active = ""
  adminPrenom: any;
  isactive = ""
  constructor(private authService: AuthService, private imageService: ImageService, private notificationService: NotificationService, location: Location, private router: Router) {
    this.token = localStorage.getItem('id_token_adminMedivisto');
    if (!this.token) {
      this.router.navigate(['/login'])
    }
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
    },
      err => {
        localStorage.clear();
        this.router.navigate(['login']);
        return false;
      });

  }
  ngOnInit() {
    var adminString = localStorage.getItem('admin');
    var adminId = JSON.parse(adminString).id;
    this.imageService.getImageAdmin(adminId).subscribe((profile:any) => {
      this.image = profile[0];
      if (this.image) {
        this.urlImage = uploadFolderRootAdmin + this.image.name
      }
    },
      err => {
        console.log(err);
        return false;
      });
   

  }
  toggleNav() {
    $('#sidebarCollapse').on('click', function() {
      $('#sidebar').toggleClass('active');
    });
  }
  
  
  closeSideBAr() {
    $('#sidebarCollapse').on('click', function() {
      $('#sidebar').toggleClass('active');
    });
  }
}

