import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-archiver-event',
  templateUrl: './popup-archiver-event.component.html',
  styleUrls: ['./popup-archiver-event.component.css']
})
export class PopupArchiverEventComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  cancel(){
    this.modal.dismiss();
  }

}
