import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { _getURL } from 'config/API_CONFIG';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { EvenementV2Service } from 'src/app/services/evenement/evenement-v2.service';

@Component({
  selector: 'app-update-event',
  templateUrl: './update-event.component.html',
  styleUrls: ['./update-event.component.css'],
  providers:[EvenementV2Service]
})
export class UpdateEventComponent implements OnInit {
  id: string;
  registerForm: FormGroup;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    width: "100%",
    minHeight: "5rem",
    placeholder: "",
    translate: "yes",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      ["insertImage", "insertVideo", "insertHorizontalRule", "removeFormat", "toggleEditorMode"],
    ],
    customClasses: [
      { name: "quote", class: "quote" },
      { name: "redText", class: "redText" },
      { name: "titleText", class: "titleText", tag: "h1" },
    ],
  };

  public files: NgxFileDropEntry[] = [];
  listFiles = [];
  filesToSaved = [];
  constructor(
    private formBuilder: FormBuilder,
    public iziToast: Ng2IzitoastService,
    private route: ActivatedRoute,
    private router: Router,
    private eventService:EvenementV2Service,
    private evenementService: EvenementV2Service // Inject the service
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      title: ['', Validators.required],
      dateDebut: ['', Validators.required],
      dateFin: ['', Validators.required],
      url: ['', Validators.required],
      description: ['', Validators.required],
      metaTitle: ['', Validators.required],
      metaDescription: ['', Validators.required],
      metaKeywords: ['', Validators.required],
      key: [''],
      image: []
    });
    this.registerForm.get('title')?.valueChanges.subscribe((title) => {
      this.updateUrlFromTitle(title);
    });
    this.id = this.route.snapshot.params["id"];
    this.getEventData();
  }
  updateUrlFromTitle(title: string): void {
    if (title) {
      const formattedUrl = title.toLowerCase().replace(/\s+/g, '-');
      this.registerForm.patchValue({ url: formattedUrl });
    }else{
      this.registerForm.patchValue({ url: "" });
    }
  }
  getEventData(): void {
    this.evenementService.getEvent(this.id).subscribe(
      (data: any) => {
        // Populate form with event data
        this.registerForm.patchValue({
          title: data.title,
          dateDebut: data.dateDebut,
          dateFin: data.dateFin,
          url: data.url,
          description: data.description,
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
          metaKeywords: data.metaKeywords,
          key: data.key
        });
      },
      (error) => {
        this.iziToast.show({
          message: 'Erreur lors de la récupération des données de l\'événement.',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          icon: 'bi-exclamation-diamond',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
      }
    );
  }

  updateEvent(): void {
    let filesList =[]
    if(this.filesToSaved.length != 0)
      {
        for(let file of this.filesToSaved)
        {
           const originalFileName = file.name;
           let fileExtension = this.getFileExtension(originalFileName);
           fileExtension ='.'+fileExtension
            const timestamp = Date.now().toString().slice(-2); // Get the last two digits of the timestamp
            let index =this.filesToSaved.indexOf(file) 
            index =index+1
            var parts = originalFileName.split('.');
           const newFileName = this.registerForm.value.title+'_'+parts[0]+'_'+index+'.'+parts[1]
           const newFile: File = new File([file], encodeURIComponent(newFileName), {type: file.type});
           const formData: FormData = new FormData();
           formData.append('file', newFile);
          this.eventService.uploadFiles(formData,this.registerForm.value.key).subscribe(e =>{
          })
          
          filesList.push(newFileName)
        }
        this.registerForm.value.image = filesList
        
      }
    if (this.registerForm.valid) {
      this.evenementService.updateEvent(this.id, this.registerForm.value).subscribe(
        (response) => {
          this.iziToast.show({
            message: 'Événement mis à jour avec succès.',
            messageColor: '#007F0F',
            titleColor: '#007F0F',
            progressBarColor: '#007F0F',
            icon: 'bi-check',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#E0F7E6',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          this.router.navigate(['/events-v2']); // Redirect after successful update
        },
        (error) => {
          this.iziToast.show({
            message: 'Erreur lors de la mise à jour de l\'événement.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
        }
      );
    } else {
      this.iziToast.show({
        message: 'Veuillez remplir tous les champs obligatoires.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
    }
  }
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
    const validExtensions = ['png', 'jpg', 'jpeg'];

    if (validExtensions.includes(fileExtension)) {
      this.listFiles.push(this.files[0].relativePath)
    }
    else {
      const targetRelativePath = this.files[0].relativePath;
      const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);

      if (targetIndex !== -1) {
        files.splice(targetIndex, 1);
      }
      this.iziToast.show({
        message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,

      });
      return;
    }
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          if (file.size < 5242880) {
            this.filesToSaved.push(file)
          }
          else {
            const targetRelativePath = this.files[0].relativePath;
            const targetIndex = this.listFiles.findIndex(entry => entry === targetRelativePath);
            if (targetIndex !== -1) {
              this.listFiles.splice(targetIndex, 1);
            }
            this.iziToast.show({
              message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
              messageColor: '#800f2f',
              titleColor: '#800f2f',
              progressBarColor: '#c9184a',
              icon: 'bi-exclamation-diamond',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay: true,
              overlayClose: true,

            });
            return;
          }
          //if( this.filesToSaved)
          if (this.filesToSaved.length > 10) {
            this.listFiles.splice(this.filesToSaved.length - 1, 1);
            this.filesToSaved.splice(this.filesToSaved.length - 1, 1);
            this.iziToast.show({
              message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
              messageColor: '#800f2f',
              titleColor: '#800f2f',
              progressBarColor: '#c9184a',
              icon: 'bi-exclamation-diamond',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay: true,
              overlayClose: true,
            });
            return;
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }
  //remove file
  deleteFileFromListFiles(file) {
    const indexToRemove = this.listFiles.findIndex(obj => obj === file);
    if (indexToRemove !== -1) {
      this.listFiles.splice(indexToRemove, 1);
      this.filesToSaved.splice(indexToRemove, 1);
    }
  }
  getFileExtension(fileName: string): string {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }
}

