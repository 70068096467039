<div class="content-wrapper flex-grow-1 bg-light min-vh-100" id="content">
    <header class="bg-surface pt-6">
        <div style="float: right; padding-right: 2%;" class="col-lg-5 col-md-6 col-sm-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item">
                    <a class="color-blue" style="cursor: default;"><i class="zmdi zmdi-home"></i> Accueil</a>
                </li>
                <li class="breadcrumb-item active color-blue" style="cursor: default;">Evénements archivés</li>
            </ul>
        </div>
        <br><br>
        <div class="container-fluid">
            <div class="row align-items-center mb-4">
                <!-- Title -->
                <div class="col-md-8 col-12 mb-2 mb-md-0 d-flex align-items-center">
                    <h1 class="h2 mb-0 ls-tight color-blue title-1">Liste des événements archivés</h1>
                </div>
                <!-- Actions -->
            </div>
            <!-- Nav -->
            <ul class="nav nav-tabs mt-4 overflow-x border-0"></ul>
        </div>
    </header>
    <main class="container-fluid pb-6">
        <div class="card shadow border-2 mt-3">
            <div class="card-header col-12">
                <div class="row">
                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
                        <h4 class="titleotherpage"><a (click)="events()">Evénements</a></h4>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
                        <h4 class="titlepage">Evénements archivés</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow border-2 mt-3">
            <!-- Row for cards -->
            <div class="row p-3">
                <!-- Card 1 -->
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4">
                    <div class="card shadow border-3">
                        <img src="../../../assets/img/Dr.Omar-site-en-ligne.webp" class="card-img-top img-fluid card-image" alt="Event Image 1">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color: #21726B;">Lancement du site pkr tunisie</h5>
                            <h5 class="card-text text-center" style="color: #21726B;">27/08/2024</h5>
                            <div class="d-flex justify-content-center mt-3">
                                <a class="mr-2"><i class="bi bi-pencil"></i></a>
                                <a href="#" class="mr-2"><i class="bi bi-eye-slash"></i></a>
                                <a href="#"><i class="bi bi-archive"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Card 2 -->
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4">
                    <div class="card shadow border-3">
                        <img src="../../../assets/img/Dr.Omar-site-en-ligne.webp" class="card-img-top img-fluid card-image" alt="Event Image 2">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color: #21726B;">Lancement du site pkr tunisie</h5>
                            <h5 class="card-text text-center" style="color: #21726B;">27/08/2024</h5>
                            <div class="d-flex justify-content-center mt-3">
                                <a href="#" class="mr-2"><i class="bi bi-pencil"></i></a>
                                <a href="#" class="mr-2"><i class="bi bi-eye-slash"></i></a>
                                <a href="#"><i class="bi bi-archive"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Card 3 -->
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4">
                    <div class="card shadow border-3">
                        <img src="../../../assets/img/Dr.Omar-site-en-ligne.webp" class="card-img-top img-fluid card-image" alt="Event Image 3">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color: #21726B;">Lancement du site pkr tunisie</h5>
                            <h5 class="card-text text-center" style="color: #21726B;">27/08/2024</h5>
                            <div class="d-flex justify-content-center mt-3">
                                <a href="#" class="mr-2"><i class="bi bi-pencil"></i></a>
                                <a href="#" class="mr-2"><i class="bi bi-eye-slash"></i></a>
                                <a href="#"><i class="bi bi-archive"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Card 3 -->
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4">
                    <div class="card shadow border-3">
                        <img src="../../../assets/img/Dr.Omar-site-en-ligne.webp" class="card-img-top img-fluid card-image" alt="Event Image 3">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color: #21726B;">Lancement du site pkr tunisie</h5>
                            <h5 class="card-text text-center" style="color: #21726B;">27/08/2024</h5>
                            <div class="d-flex justify-content-center mt-3">
                                <a href="#" class="mr-2"><i class="bi bi-pencil"></i></a>
                                <a href="#" class="mr-2"><i class="bi bi-eye-slash"></i></a>
                                <a href="#"><i class="bi bi-archive"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Card 3 -->
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4">
                    <div class="card shadow border-3">
                        <img src="../../../assets/img/Dr.Omar-site-en-ligne.webp" class="card-img-top img-fluid card-image" alt="Event Image 3">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color: #21726B;">Lancement du site pkr tunisie</h5>
                            <h5 class="card-text text-center" style="color: #21726B;">27/08/2024</h5>
                            <div class="d-flex justify-content-center mt-3">
                                <a href="#" class="mr-2"><i class="bi bi-pencil"></i></a>
                                <a href="#" class="mr-2"><i class="bi bi-eye-slash"></i></a>
                                <a href="#"><i class="bi bi-archive"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
