<div class="wrapper" *ngIf="admin">
    <!-- Sidebar  -->
    <nav id="sidebar" class="nav1">
      <div class="sidebar-header">
        <img src="assets/medivisto-blanc.png" class="logo-mediv">
        <div class="image-div">
          <img src="assets/Groupe%2068.png" class="image-admin" *ngIf="!image">
          <img src="{{urlImage}}" class="image-admin" *ngIf="image">
        </div>
        <div class="name-admin" routerLink="/profil" title="Profil">
          <h5 style="text-transform: capitalize;">{{admin.nom}} {{admin.prenom}}</h5>
        </div>
      </div>
      <ul class="list-unstyled components">
        <li *ngIf="admin.roles.gestionAccueil">
          <a routerLink="/accueil">
            <div>
              <div class="icon-menu1"> .</div><span class="span-menu">Accueil</span>
            </div>
          </a>
        </li>
        <li *ngIf="admin.roles.gestionMedecins">
          <a routerLink="/medecins">
            <div>
              <div class="icon-menu2"> .</div><span class="span-menu">Médecins</span>
            </div>
          </a>
        </li>
        <li *ngIf="admin.roles.gestionMedecins">
          <a routerLink="/patients">
            <div>
              <div class="icon-menu3"> .</div><span class="span-menu">Patients</span>
            </div>
          </a>
        </li>
        <li *ngIf="admin.roles.gestionAgenda">
          <a [routerLink]="['/agenda']">
            <div>
              <div class="icon-menu4"> .</div><span class="span-menu">Agenda</span>
            </div>
          </a>
        </li>
        <!-- <li *ngIf="admin.roles.gestionEvenements">
          <a routerLink="/events">
            <div>
              <div class="icon-menu5"> .</div><span class="span-menu">Evénements</span>
            </div>
          </a>
        </li> -->
        <li *ngIf="admin.roles.gestionEvenements">
          <a routerLink="/events-v2">
            <div>
              <div class="icon-menu5"> .</div><span class="span-menu">Evénements V2</span>
            </div>
          </a>
        </li>
        <!--<li *ngIf="admin.roles.gestionForums">
          <a routerLink="/forum">
            <div>
              <div class="icon-menu6"> .</div><span class="span-menu">Forum</span>
            </div>
          </a>
        </li>-->
        <li *ngIf="admin.roles.gestionAdmins">
          <a routerLink="/admins">
            <div>
              <div class="icon-menu7"> .</div><span class="span-menu">Admins</span>
            </div>
          </a>
        </li>
        <li *ngIf="admin.roles.gestionConseils">
          <a routerLink="/conseils">
            <div>
              <div class="icon-menu8"> .</div><span class="span-menu">Conseils</span>
            </div>
          </a>
        </li>
        <li *ngIf="admin.roles.gestionConseils">
          <a routerLink="/medicaments">
            <div>
              <div class="icon-menu9"> .</div><span class="span-menu">Médicaments</span>
            </div>
          </a>
        </li>
        <li *ngIf="admin.roles.gestionConseils">
          <a routerLink="/articles">
            <div>
              <div class="icon-menu8"> .</div><span class="span-menu">Articles</span>
            </div>
          </a>
        </li>
      </ul>
    </nav>
    <!-- Page Content  -->
    <div id="content">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
  
          <button type="button" id="sidebarCollapse" class="btn btn-info" (click)="toggleNav()" title="Cliquer deux fois">
            <img src="assets/burger.png">
          </button>
          <button class="btn btn-dark d-inline-block d-lg-none ml-auto " title="Cliquer deux fois" (click)="toggleNav()" type="button"  data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img src="assets/reglage.png" style="width: 27px;">
          </button>
          <div class="collapse navbar-collapse notif" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item active dropdown">
                <a class="nav-link  dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#"><img src="assets/bell%20(1).png"><span _ngcontent-c2="" class="badge"
                    style="color: white;background-color: red;margin-left: -13px;">5</span></a>
                <div class="dropdown-menu animated" id="notification-dropdown" aria-labelledby="dropdownMenuButton">
                  <div class="dropdown-header">
                    <span class="triangle"></span>
                    <span class="heading">Notifications</span>
                    <span class="count" id="dd-notifications-count"></span>
                  </div>
                  <div class="dropdown-body">
                    <div class="notification new">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/man.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <div class="row">
                              <div class="col-9">
                                <h6 class="objet">Demande d'inscription</h6>
                              </div>
                              <div class="col-1 ">
                                <div class=" notifDiv">
                                  <a title="Marquer comme lu" style="cursor: pointer"><img src="assets/markLu.png" class="markLue"></a>
                                </div>
                              </div>
                            </div>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification new">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/man.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <div class="row">
                              <div class="col-9">
                                <h6 class="objet">Demande d'inscription</h6>
                              </div>
                              <div class="col-1">
                                <div class=" notifDiv">
                                  <a title="Marquer comme lu" style="cursor: pointer"><img src="assets/markLu.png" class="markLue"></a>
                                </div>
                              </div>
                            </div>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification new">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/man.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <div class="row">
                              <div class="col-9">
                                <h6 class="objet">Demande d'inscription</h6>
                              </div>
                              <div class="col-1">
                                <div class=" notifDiv">
                                  <a title="Marquer comme lu" style="cursor: pointer"><img src="assets/markLu.png" class="markLue"></a>
                                </div>
                              </div>
                            </div>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/woman.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <h6 class="objet">Demande d'inscription</h6>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/woman.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <h6 class="objet">Demande d'inscription</h6>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/woman.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <h6 class="objet">Demande d'inscription</h6>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/woman.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <h6 class="objet">Demande d'inscription</h6>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/woman.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <h6 class="objet">Demande d'inscription</h6>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification">
                      <div class="row">
                        <div class="col-3">
                          <div class="notification-image-wrapper">
                            <div class="notification-image">
                              <img src="assets/woman.png" alt="" class="image-user-notif">
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <div class="notification-text">
                            <h6 class="objet">Demande d'inscription</h6>
                            <p class="content">John Doe souhaite s'inscrire en tant que Médecin</p>
                            <p class="time">03/03/2021 à 15:17</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <a class="notif-footer" routerLink="notifications">Voir toutes les activités</a>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" title="Se déconnecter" [routerLink]="['/logout']"><img src="assets/logout.png"></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
  