<div id="scrolltoverify">

</div>
<!------------------ Liste des roles   ----------------------->
<div class="informationPat3" >
  <div class="row bar">
    <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b >Liste des Roles </b></a></div>
  </div>
  <div class="div-tab2">
    <form class="" >
      <div class="row" style="overflow-x:auto;" >
        <table class="table  "  >
          <tbody>
          <tr class=" gauche">
            <td  class=" gauche"  >Gestion des rendez-vous</td>
            <td  class=" gauche" >
              <label class="switch">
                <input type="checkbox" id="TriSeaPrimary" name="gestionRdvs"  [(ngModel)]="gestionRdvs" >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  > Gestion des patients  </td>
            <td  class=" gauche" >
              <label class="switch">
                <input type="checkbox" id="TriSeaPrimary1" name="gestionPatient" [(ngModel)]="gestionPatient" >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  > Gestion d'agenda  </td>
            <td  class=" gauche" >
              <label class="switch">
                <input type="checkbox" id="TriSeaPrimary2" name="gestionAgenda"  [(ngModel)]="gestionAgenda" >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  > Gestion des documents  </td>
            <td  class=" gauche" >
              <label class="switch">
                <input id="TriSeaPrimary3" name="gestionDocuments" type="checkbox" [(ngModel)]="gestionDocuments"  >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  > Gestion des certificats  </td>
            <td  class=" gauche" >
              <label class="switch">
                <input id="TriSeaPrimary4" name="gestionCertificats" type="checkbox" [(ngModel)]="gestionCertificats"  >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  > Gestion des ordonnances  </td>
            <td  class=" gauche" >
              <label class="switch">
                <input id="TriSeaPrimary5" name="gestionOrdonnaces" type="checkbox" [(ngModel)]="gestionOrdonnaces" >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  > Gestion des médecins  </td>
            <td  class=" gauche" >
              <label class="switch">
                <input id="TriSeaPrimary6" name="gestionMedecins" type="checkbox" [(ngModel)]="gestionMedecins" >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  > Gestion des évenements  </td>
            <td  class=" gauche" >
              <label class="switch">
                <input id="TriSeaPrimary7" name="gestionEvenements" type="checkbox" [(ngModel)]="gestionEvenements" >
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr class=" gauche">
            <td  class=" gauche"  >
              <div class="row" style="margin-left: 11%;">
                <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"><button class="btn-valider" (click)="modifierRoles()"><img src="assets/valider.png" class="iconBoutton" >Enregistrer</button></div>
                <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"><button class="btn-annule" routerLink="/medecins" ><img src="assets/annuler.png" class="iconBoutton" >Annuler</button></div>
              </div>
            </td>
            <td  class=" gauche" >
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </form>

  </div>
</div>
