<div class="modal-header">
    <h5 class="modal-title">Masquer un événement</h5>
    <button
        type="button"
        class="exit-button"
        aria-label="Fermer"
        (click)="modal.dismiss('Cross click')"
    >
        <i class="fa-regular fa-circle-xmark"></i>
    </button>
</div>

<div class="modal-body">
    <div class="form-row">
        <div class="col">
            <div class="form-group text-center">
                <p>Êtes-vous sûr(e) de vouloir masquer cet événement ?</p>
            </div>
        </div>
    </div>

    <div class="form-group text-center mt-2">
        <button (click)="cancel()" type="reset" class="btn btn-secondary mr-3">Annuler</button>
        <button class="btn btn-primary">Masquer</button>
    </div>
</div>
