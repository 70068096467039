import {Injectable} from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from 'src/app/services/auth/auth.service';
import { Observable} from 'rxjs';


@Injectable()
export class AuthConseilGuard implements CanActivate{
  admin:any;
  constructor( private authService:AuthService,private router:Router){

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if(!this.admin){
        localStorage.clear();
        this.router.navigate(['/login']);
        return false;
      }
      if(this.admin && !this.admin.roles ){
        localStorage.clear();
        this.router.navigate(['/login']);
        return false;
      }
      if(this.admin && this.admin.roles && !this.admin.roles.gestionConseils){
        this.router.navigate(['/notFound']);
        return false;
      }
      return true;
    },
      err => {
        localStorage.clear();
        this.router.navigate(['login']);
        return false;
      });
      return true;
  }
}
//to protect routers
